.dane-mb {
    margin-bottom: 10px;
}

.dane-mb-midi {
    margin-bottom: 12px;
}

.container {
    max-width: 1200px
}

.dropdown-menu {
    margin-left: 50px !important;
    top: -10% !important;
    min-width: 20px !important;
}

@media (max-width: 992px) {
    .dropdown-menu {
        margin-left: 50px !important;
        position: absolute !important;
        z-index: 1000 !important;
        margin-top: -20px;
        min-width: 20px !important;
    }
}

.grey-stripe {
    background-color: #8a8a8a;
    height: 1px;
}

.top-border {
    border-top: 1px solid #e2e2e2
}

.icon {
    min-width: 20px;
    margin-right: 5px;
    align-self: center;
}

@media (min-width: 1200px) {
    .bgr-w {
        background-color: #ffffff;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
        height: 100%;
    }
}


body {
    margin-bottom: 125px;
}

html {
    position: relative;
    min-height: 100%;
}

.txt-input {
    height: 48px;
    margin-top: 24px;
    background-color: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
}

.grey-stripe {
    background-color: #8a8a8a;
    height: 1px;
}

.top-border {
    border-top: 1px solid #e2e2e2;
}

.footer-txt, .footer-txt:hover {
    font-family: 'Roboto';
    font-size: 14px;
    color: #5a5a5a;
    text-decoration: none;
}

@media (min-width: 576px) {
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.footer {
    bottom: 0;
    width: 100%;
    margin-top: 80px;
}

.dane-mb {
    margin-bottom: 10px;
}

.dane-mb-sm {
    margin-bottom: 4px;
}

.dane-mb-midi {
    margin-bottom: 12px;
}


.circle-next {
    padding-left: 5px;
    padding-right: 5px;
}

.statement {
    padding: 6px 15px 6px 22px;
}

.statement-close {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: 11px;
}

.btn-max1 {
    width: 240px;
}

.mobile-menu-item {
    margin-top: 20px;
}

.grey-stripe {
    background-color: #f0f0f0;
    margin-top: 19px;
}


.chart-txt {
    color: #8A8A8A;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    line-height: normal;
    padding-top: 10px;
}

.dane-mt1 {
    margin-top: -9px;
}

.dane-mt-2 {
    margin-bottom: 2px;
}

.bgr-w-box {
    background-color: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
}


.txt-l-r {
    text-align: right;
}

@media (min-width: 992px) {
    .txt-l-r {
        text-align: left;
    }
}

.h-box {
    height: 100%;
}

.border-l {
    border-left: 1px solid #EEEEEE;
}

.border-b {
    border-bottom: 1px solid #EEEEEE;
}

.logo-box {
    height: 54px;
}

.arrow-back {
    padding-bottom: 13px;
}


.ebok-no-underline:hover {
    text-decoration-line: none;
}

.dashboard-services {
    font-size: 14px;
}

.no-pointer:hover {
    text-decoration: none;
}

.input-group > .input-group-prepend {
    flex: 0 0 50%;
}

.input-group .input-group-text {
    width: 100%;
}

.changeDataFormInvalid{
    padding-left: 50% !important;
}

/*wyłączenie exportu do CSV z powodu błednie generowanych danych*/

.apexcharts-menu-item.exportCSV {
    display: none;
}