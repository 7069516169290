.debug-sidebar ::-webkit-scrollbar-button {
    display: none;

}

.debug-sidebar ::-webkit-scrollbar-thumb {
    background-color: #222;
    border: 10px solid rgba(255, 255, 255, 0)!important;
    background-clip: padding-box !important;
}

.debug-sidebar ::-webkit-scrollbar-track {
    background-color: #ef890e;
    border: 10px solid rgba(255, 255, 255, 0)!important;
    background-clip: padding-box !important;
}

.debug-sidebar ::-webkit-scrollbar-thumb:hover {
    background-color: #CCC;
}

.debug-sidebar ::-webkit-scrollbar-track:hover {
    background-color: #CCC;
}

.debug-sidebar ::-webkit-scrollbar {
    width: 18px;
    height: 18px;
}

.debug-sidebar ::-webkit-scrollbar-corner {
    background-color: transparent;
}

.debug-sidebar pre {
    overflow: unset;
}