.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scroll::-webkit-scrollbar-button {
  width: 6px;
  height: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #3a3a3a;
  border: 0px none #ffffff;
  border-radius: 17px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #2a2a2a;
}

.custom-scroll::-webkit-scrollbar-thumb:active {
  background: #2a2a2a;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #d4d4d4;
  border: 0px none #ffffff;
  border-radius: 45px;
}

.custom-scroll::-webkit-scrollbar-track:hover {
  background: #c5c5c5;
}

.custom-scroll::-webkit-scrollbar-track:active {
  background: #a7a7a7;
}

.custom-scroll::-webkit-scrollbar-corner {
  background: transparent;
}

