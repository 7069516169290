
.text-man-1 {
    font: 15px/18px Roboto;
    letter-spacing: 0;
    color: #555555;
}

.txt-black-big {
    color: #000000;
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 600;
}

.txt-black-medium {
    color: #000000;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 600;
    line-height: normal;
}

.txt-black-small, .txt-black-small:hover {
    color: #000;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    text-decoration: none;
    line-height: normal
}

.txt-black-vbig {
    color: #000000;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -1px;
}

.txt-black-xbig {
    color: #000000;
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: 600;
    margin-top: 8px;
}

.txt-grey-small {
    color: #5a5a5a;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
}

.txt-input {
    height: 48px;
    margin-top: 24px;
    background-color: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
}

.txt-news-short-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.txt-news-short-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.txt-news-short-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.txt-statement {
    color: #ffffff;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 200;
    margin-top: 15px;
    padding-right: 17px;
}

.txt-violet-big {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Roboto Condensed';
    line-height: normal;
    letter-spacing: -1px;
}

.txt-violet-small, .txt-violet-small:hover {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    word-break: break-all;
}

.txt-violet-vbig {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -1px;
}

.txt-violet-xsmall {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    text-decoration: none
}

.txt-white-big {
    color: #ffffff;
    font-size: 25px;
    font-weight: 200;
    font-family: 'Roboto Condensed';
    line-height: normal;
    letter-spacing: -1px;
}

.txt-white-small {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 100;
    text-decoration: none;
    line-height: normal;
}

.txt-red-medium {
    color: #FF0000;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 600;
    line-height: normal;
}

