.message-table {
    font-size: 0.8rem !important;
}

.message-table-icon {
    font-size: 16px !important;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

table {
    border-collapse: collapse;
}

tr {
    border-bottom: 1px solid #fff;
}

tr, td {
    padding: 0;
    margin: 0;
}

tr td div {
    max-height: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.3s, padding 0.3s;
}

tr.active td div {
    max-height: 500px;
    padding: 10px 10px;
    transition: padding 0.3s, max-height 0.3s;
}

tr.active td {
    padding: 0 !important;
}

tr.message-row td {
    padding: 0 !important;
}

tr.header-active {
    background-color: #e6e6e6 !important;
}