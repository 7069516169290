.message {
    height: 16px;
    border-radius: 4px;
    font-size: 10px;
    padding: 0 5px 0 5px;
    color: #ffffff;
    position: absolute;
    top: 16px;
    right: 0;
}

.message-btn {
    position: relative;
}

