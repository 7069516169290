.bgr-blue {
    background-color: #41ACC8;
    border-radius: 3px;
}

.bgr-grey {
    background-color: #F8F8F8;
}

.bgr-orange {
    background-color: #EF5252;
    border-radius: 3px;
}

.bgr-main {
    background-color: #841C75;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
}

.bgr-w {
    background-color: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
}

