
.btn-max1 {
    width: 240px
}


.btn-signin, .btn-signin:hover, .btn-signin:focus, .btn-signin:active {
    height: 48px;
    border-radius: 4px;
    font-size: 15px !important;
    outline: none;
    border: none;
    box-shadow: none
}

.btn-signin-thin, .btn-signin-thin:hover, .btn-signin-thin:focus, .btn-signin-thin:active {
    height: 42px;
    border-radius: 4px;
    font-size: 15px !important;
    outline: none;
    border: none;
    box-shadow: none
}

.btn-signin-white-thin, .btn-signin-white-thin:hover {
    height: 42px;
    background: #fff 0 0 no-repeat padding-box !important;
    border-radius: 4px;
    font-size: 15px !important;
    outline: none !important;
    font-weight: 500 !important;
}

.btn:not(:disabled):not(.disabled) {
    border: none
}


.btn-border, .btn-border:hover, .btn-border:focus, .btn-border:active {
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px !important;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    font-weight: 500;
}

.btn-border-2, .btn-border-2:hover, .btn-border-2:focus, .btn-border-2:active {
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px !important;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    font-weight: 500;
}