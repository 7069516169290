.circle li {
    background-image: url("../../assets/img/circle-next-hot.svg") !important;
    background-repeat: no-repeat !important;
    background-color: rgba(0,0,0,0) !important;
    width: 16px !important;
    height: 16px !important;
}

.carousel-indicators {
    position: absolute !important;
    bottom: -20px !important;
}

.active {
    text-transform: none !important;
}