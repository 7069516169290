
@media (max-width: 577px) {
    .serviceDetails {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
}

@media (min-width: 577px) {
    .serviceDetails {
        padding-bottom: 0;
        margin-left: 0;
        /*margin-right: 20px !important;*/
        padding-top: 10px;
    }
}


