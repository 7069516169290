.profile-name-card {
    height: 29px;
    text-align: left;
    letter-spacing: 0;
    color: #3E1C70;
    opacity: 1;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 38px;
}

.login-box, .login-box:focus, .login-box:visited, .login-box:active, .login-box:hover {
    height: 48px;
    margin-top: 24px;
    background-color: #ffffff;
}

.forgot-password, .forgot-password:hover {
    color: #979797;
    text-decoration: underline;
    font-size: 14px;
}

input::placeholder {
    font-size: 15px;
}

.txt-log-wersal {
    color: #4A4A4A;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
}

.btn-reg, .btn-reg:hover {
    border-radius: 4px;
    height: 48px;
    background-color: #ffffff;
    font-weight: 400;
    font-family: 'Roboto';
    text-decoration: none;
}

.btn-primary {
    background-color: #8D1B78;
}

.login-box, .login-box:focus, .login-box:visited, .login-box:active, .login-box:hover {
    height: 48px;
    margin-top: 24px;
    background-color: #ffffff;
}

.forgot-password, .forgot-password:hover {
    color: #979797;
    text-decoration: underline;
    font-size: 14px;
}

input::placeholder {
    font-size: 15px;
}

.txt-log-wersal {
    color: #4A4A4A;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
}


#inputEmail {
    color: #4A4A4A;
    font-family: 'Roboto';
    opacity: 1;
}

#inputPassword {
    color: #4A4A4A;
    font-family: 'Roboto';
    opacity: 1;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #d5d5d5;
    outline: 0;
    box-shadow: none;
}

