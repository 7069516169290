.active {
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: none
}

.btn-max1 {
    width: 240px
}

.circle-next {
    padding-left: 5px;
    padding-right: 5px
}

.grey-link-ico, .grey-link-ico:hover {
    font-family: 'Roboto';
    font-size: 14px;
    color: #575757;
    opacity: 1;
    text-decoration: none;
}

.grey-link-ico-chat {
    background-image: url(../../assets/img/comments-alt.svg);
    background-repeat: no-repeat;
    padding-left: 41px;
    background-size: 27px;
    padding-top: 2px;
    padding-bottom: 7px
}

.grey-link-ico-how {
    background-image: url(../../assets/img/key.svg);
    background-repeat: no-repeat;
    padding-left: 34px;
    background-size: 23px;
    padding-top: 2px;
    padding-bottom: 7px
}

.icon {
    min-width: 20px;
    margin-right: 5px;
    align-self: center
}

.logo-small {
    height: 30px
}

.mobile-bgr {
    background-color: #fff;
    width: auto;
    padding: 25px 29px 40px 25px;
    min-height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1
}

.mobile-menu-item {
    margin-top: 20px
}

.nav-grey-stripe {
    border-bottom: 1px solid #e0e0e0
}

.nav-h {
    height: 72px
}

.nav-link, .nav-link:hover, .nav-link:focus, .nav-link:active {
    color: #fff;
    border: none;
    box-shadow: none
}

.nav-link:hover, .nav-link:focus {
    border: none;
    box-shadow: none;
    color: #54555a
}

.nav-link {
    height: 18px;
    font-family: Roboto;
    font-size: 15px;
    color: #54555a;
    font-weight: 500;
    line-height: 18px
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #54555a
}

.navbar-light .navbar-nav .nav-link {
    color: #54555a
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    background-color: transparent;
    border: none
}

.navbar {
    background-color: #fff;
    margin: 0;
    padding: 0
}

@media (min-width: 1200px) {
    .bgr-w {
        background-color: #fff;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
        height: 100%
    }
}


@media (min-width: 992px) {
    .menu-1-txt:hover {
        color: #555;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
    }
}

@media (min-width: 992px) {
    .menu-1-txt {
        font-family: Roboto;
        font-size: 15px;
        color: #555;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 4px solid #fff;
        border-spacing: 20px;
        height: 64px
    }
}

a:hover {
    color: #555;
    text-decoration: underline
}

body {
    margin-bottom: 125px
}

html {
    position: relative;
    min-height: 100%
}


.menu-1-txt {
    font-family: Roboto;
    font-size: 15px;
    color: #555;
    line-height: 18px;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 4px solid #fff;
    border-spacing: 20px;
    height: 64px
}