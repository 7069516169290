.spinner-lg{
    width: 3rem;
    height: 3rem;
}
.loader {
    text-align: center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000000000000000 !important;
    background: rgba(0,0,0,0.5);
}
.loader-top{
    height: 45%;
}
