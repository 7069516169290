.footer {
    bottom: 0;
    width: 100%;
    margin-top: 80px;
}

.footer {
    bottom: 0;
    width: 100%;
}

.footer-txt .footer-txt:hover {
    font-family: 'Roboto';
    font-size: 14px;
    color: #5d5d5d;
    text-decoration: none;
}

@media (min-width: 576px) {
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media (min-width: 576px) {
    .footer {
        bottom: 0;
        width: 100%;
    }
}