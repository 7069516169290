
.icon-w-text p {
    margin: 0;
}

.icon-w-text {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.step-box-stripe-v1 {
    border-right: none
}

.step-box-stripe-v2 {
    border-right: none
}

.step-txt {
    color: #4A4A4A;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: initial;
}

.circle-number {
    position: absolute;
    font-size: 15px;
    padding-top: 0px;
    padding-left: 6px;
    color: #fff;
    font-family: 'Roboto';
    font-weight: 500
}

.register-statute-checkbox {
    display: none;
}

.icon-w-text {
    display: flex;
    flex-flow: row;
    align-items: center
}

@media (min-width: 1200px) {
    .step-box-stripe-v2 {
        border-right: 1px solid #e2e2e2;
    }
}

@media (min-width: 768px) {
    .step-box-stripe-v1 {
        border-right: 1px solid #e2e2e2;
    }
}
