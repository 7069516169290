.red-dot {
    font-size: 8px;
    color: rgb(255, 0, 0);
    padding-bottom: 2px;
}

.green-dot {
    font-size: 8px;
    color: rgb(0, 128, 0);
    padding-bottom: 2px;
}

