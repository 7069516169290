.cookies-popup {
    position: fixed;
    width: 90% !important;
    max-width: 100% !important;
    bottom: 0;
    z-index: 10000;
    margin-left: 5%;
}


