.service-card {
    background-color: #ffffff;
    padding: 0 20px 20px;
    height: 100% !important;
}

@media (max-width: 577px) {
    .card-opened{
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-right: 0 !important;
    }
}

@media (min-width: 577px) {
    .card-opened{
        padding-right: 0 !important;
        transition: padding-right 0.5s;
    }
}
@media (max-width: 577px) {
    .card-closed{
        padding: 0;
        transition: padding 0.5s
    }
}

@media (min-width: 577px) {
    .card-closed{
        padding-right: 0;
        transition: padding-right 0.5s;
    }
}


