/*text and icon color*/
.ebok-color {
    color: #00a2a8 !important;
}

.red-color {
    color: #FF0000 !important;
}

/*background colors*/
.background-main {
    background: #00a2a8 0 0 no-repeat padding-box !important;
}

/*active nav link*/
.nav-border-color:active, .nav-border-color:hover {
    border-bottom: 4px solid #00a2a8 !important;
}

/*navbar bottom color*/
@media (min-width: 992px) {
    .active {
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
    }

    .active li span {
        border-bottom: 4px solid #00a2a8
    }
}

.btn-border-color {
    border: 1px solid #00a2a8 !important;
}

.btn-outline-primary {
    color: #00a2a8 !important;
    border: 1px solid #00a2a8 !important;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #00a2a8 !important;
}
